import _logo from './logo.svg';
import _logoFull from './logo_full.svg';
import _Contraband from './Contraband';
import _Insurance from './Insurance';

export const LOGO = _logo;
export const LOGO_FULL = _logoFull;
export const Contraband = _Contraband;
export const Insurance = _Insurance;

export { SHIPPING_SERVICES } from './shipping_services';

export const TELEGRAM_BOT_API_KEY = '6464169971:AAFDGKY1KJ_I18awfRpW_vquuFGH9FrzhWg';
export const TELEGRAM_BOT_CHAT_ID = -4098024830;
export const TELEGRAM_BOT_URI = `https://api.telegram.org/bot${TELEGRAM_BOT_API_KEY}/sendMessage`;

export const COMPANY_FULL_NAME = '佳联国际转运';
export const COMPANY_SHORT_NAME = '佳联';
export const COMPANY_WECHAT_ID = 'jialian_56';
export const COMPANY_WORKING_HOURS = '周一至周五+周日 10:00-22:00，周六休息';
export const COMPANY_PACKING_DAYS_GUARANTEE = 2;
export const WAREHOUSE_ADDRESS = '广东省广州市白云区鹤龙街道细彭岭路19号';
export const WAREHOUSE_PHONE = '18664898502';
export const WAREHOUSE_ZIP_CODE = '510440';

export const COUNTRIES_SUPPORTED = {
  US: '美国',
};

export const CURRENCIES_SUPPORTED = {
  CNY: '人民币',
  // USD: '美元',
};

type PaymentInfoType = {
  show: boolean,
  alipay?: {
    enabled: boolean,
    account?: string,
    accountName?: string,
  },
  taobao?: {
    enabled: boolean,
    link?: string,
  },
  wechatPay?: {
    enabled: boolean,
    account?: string,
    accountName?: string,
  },
  paypal?: {
    enabled: boolean,
    account?: string,
    accountName?: string,
  },
};

export const PAYMENT_INFO: PaymentInfoType = {
  show: true,
  alipay: {
    enabled: true,
    account: '18664898502',
    accountName: '向海栋',
  },
  taobao: {
    enabled: true,
    link: 'https://item.taobao.com/item.htm?id=588796723181',
  },
  wechatPay: {
    enabled: true,
    account: '18664898502',
    accountName: '向海栋',
  },
  paypal: {
    enabled: false,
  },
};

export const ADMIN_LOGIN_PAGE_SHOW_LOGO = false;
export const ADMIN_LOGIN_PAGE_SHOW_COMPANY_NAME = true;
export const TICKET_SYSTEM_ENABLED = false;
export const SHOW_INSURANCE_PAGE = true;
export const EMAIL_NOTIFICATION_ENABLED = false;
export const ORDER_UPLOAD_IMAGE = true;
export const PACKAGE_UPLOAD_IMAGE = false;
export const DIRECT_ORDER_SELECT_SERVICE_ENABLED = true;
export const SHOW_SHIPPING_ROUTE_PAGE = true;
export const SHOW_CURRENCY_OPTION_IN_ORDER_PAGE = false;

export const PRICE_PER_PACKAGE = 0.2;
