import React from 'react';
import { Card } from 'antd';

import PageTitle from '../../components/PageTitle';

import './index.css';

const content = `
1. 货物在 DHL/UPS/FEDEX 提取前丢件扣关，一律赔偿40元/kg，不退运费
2. 货物在 DHL/UPS/FEDEX 提取后丢件扣关，最高赔偿100美金/票，不退运费
3. 若产生纠纷，一切解释权归本公司所有

`;

function Insurance() {
  return (
    <div className="Insurance">
      <PageTitle>关于赔偿</PageTitle>

      <Card bordered={false}>
        <div className="Insurance-content">{content}</div>
      </Card>
    </div>
  );
}

export default Insurance;
